import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

function NurseChatSetup() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [amazonEmail, setAmazonEmail] = useState('');

  const handleSave = async () => {
    if (!amazonEmail) {
      alert('Please enter your Amazon Alexa email');
      return;
    }
    
    try {
      const response = await axios.post(
        import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/patient/alexa-email",
        { email: amazonEmail },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
      navigate("/visual-data/nurse-chat");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to save email");
      console.error(error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <Link to={"/dashboard/nurse-chat"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Alexa Setup
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="w-full max-w-md bg-white rounded shadow-md p-6 mt-14">
          <h1 className="text-2xl font-semibold mb-2">
            Amazon Alexa Setup
          </h1>
          
          <p className="text-gray-700 mb-4">
            Please follow the below instructions
          </p>
          <p className="text-gray-700 mb-4">
            Please provide your Amazon user ID (email) to use Alexa below. 
            If you do not have one, please register on Amazon{' '}
            <a
              href="https://developer.amazon.com/alexa/console/ask"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              here
            </a>
            .
          </p>
          
          <label htmlFor="alexaEmail" className="block font-medium mb-2">
            Enter Amazon Alexa Email
          </label>
          
          <input
            id="alexaEmail"
            type="email"
            className="block w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Enter Amazon Alexa Email"
            value={amazonEmail}
            onChange={(e) => setAmazonEmail(e.target.value)}
          />
          
          <button
            onClick={handleSave}
            className="block w-full bg-blue-600 text-white font-semibold py-2 rounded hover:bg-blue-700 transition-colors"
          >
            Save
          </button>
          
          <div className="flex justify-center mt-6">
            <img
              src="/alexa.png"
              alt="Alexa Logo"
              className="h-12 w-12"
            />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default NurseChatSetup; 