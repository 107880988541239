import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const AppointmentVerification = () => {
  const { appointmentId } = useParams();
  const [verificationResult, setVerificationResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [newDateTime, setNewDateTime] = useState("");

  const API_BASE = import.meta.env.VITE_APP_BASE_API;
  const API_VERSION = import.meta.env.VITE_APP_API_VERSION;
  const API_ENDPOINT = `${API_BASE}${API_VERSION}/appointment/get`;

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Format: "YYYY-MM-DD"
  };

  useEffect(() => {
    const verifyAppointment = async () => {
      setIsLoading(true);
      setError(null);
      setVerificationResult(null);

      if (!appointmentId) {
        setError("Appointment ID is missing.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_ENDPOINT}/${appointmentId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setVerificationResult(data.appointment);
        setNewDateTime(formatDateForInput(data.appointment?.appointment_date));
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAppointment();
  }, [appointmentId, API_ENDPOINT]);

  const handleUpdate = async (status) => {
    try {
      const response = await fetch(`${API_BASE}${API_VERSION}/appointment/patient/update/${verificationResult._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setVerificationResult(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`${API_BASE}${API_VERSION}/appointment/patient/update/${verificationResult._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ appointment_date: newDateTime }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setVerificationResult(data);
      setIsEditing(false);
      // Refresh the page after successful update
      window.location.reload();
    } catch (err) {
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md text-center">
        {error && <div className="text-red-500 font-semibold mb-4">{error}</div>}

        {verificationResult && verificationResult.status === "Accepted" ? (
          <>
            <div className="text-green-500 text-6xl mb-4">✔</div>
            <h2 className="text-xl font-bold text-green-600">Success!</h2>
            <p className="text-gray-600">{verificationResult.message}</p>
          </>
        ) : (
          <>
            <div className="text-red-500 text-6xl mb-4">✖</div>
            <h2 className="text-xl font-bold text-red-600">Failed!</h2>
            <p className="text-gray-600">{verificationResult?.message || "Appointment update failed."}</p>
          </>
        )}

        {!error && verificationResult && (
          <div className="mt-6 space-y-3">
            {!isEditing ? (
              <>
                <p className="text-gray-700 font-semibold">
                  Appointment Date: {new Date(verificationResult.appointment_date).toLocaleDateString()}
                </p>
                <button onClick={() => handleUpdate("Accepted")} className="w-full px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600">Confirm</button>
                <button onClick={() => setIsEditing(true)} className="w-full px-4 py-2 bg-yellow-500 text-white rounded-md shadow hover:bg-yellow-600">Edit</button>
                <button onClick={() => handleUpdate("Rejected")} className="w-full px-4 py-2 bg-red-500 text-white rounded-md shadow hover:bg-red-600">Cancel</button>
              </>
            ) : (
              <div>
                <input 
                  type="date" 
                  value={newDateTime}
                  onChange={(e) => setNewDateTime(e.target.value)} 
                  className="w-full p-2 border border-gray-300 rounded-md" 
                />
                <button onClick={handleSaveEdit} className="w-full mt-3 px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600">Save</button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentVerification;