import { AppBar, Toolbar, Typography, Container, Paper, IconButton } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function HipaaCompliancePage() {
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
        <Link to={"/settings"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6" component="div" >
            Hippa Compliance
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ paddingY: 4 }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h4" gutterBottom>
            SmartNurse.ai is HIPAA compliant
          </Typography>

          <Typography variant="h5" gutterBottom>
            Mobile Data Security and HIPAA Compliance
          </Typography>

          <Typography variant="body1" paragraph>
            Healthcare providers and other HIPAA-covered entities have embraced
            the mobile technology revolution and are allowing the use of
            Smartphones, tablets, and other portable devices in hospitals,
            clinics, and other places of work; however, if mobile data security
            measures are insufficient, covered entities are at risk of violating
            HIPAA regulations. If that occurs, heavy fines can follow.
          </Typography>

          <img
            src="https://www.hipaajournal.com/wp-content/uploads/2015/09/mobile-device-security-hipaa-journal-2.png"
            alt="Mobile Data Security"
            style={{ maxWidth: "100%", height: "auto", marginBottom: "16px" }}
          />

          <Typography variant="h6">
            Source:{" "}
            <Link
              href="https://www.hipaajournal.com/mobile-data-security-and-hipaa-compliance"
              target="_blank"
              rel="noopener"
            >
              Mobile Data Security and HIPAA Compliance
            </Link>
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}
