import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";

function NurseChatAssessments() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedSkill, setSelectedSkill] = useState('');

  const assessments = [
    {
      title: 'Scheduler',
      alertMessage:
        'Please say "Alexa Open Smart Nurse Scheduler" on the Alexa app, and after completion you can view your responses in the ANSWERS tab.',
      skillLink: 'https://skills-store.amazon.com/deeplink/tvt/dd6118814b5c6d661888bcb4ee898f99d7b26165d0b96bff647dc2e6b76d72a82c399c22644f2d78df7b91c548eff7dd7a2b27f47c6c851fa7c8819e0fe2919aa3cd2f91b40e2847f5a926da142af28bd83b74fd7f1e19f0dd0e3282a4a3a21e68cf88846abe5cb26cadbf916763a784'
    },
    {
      title: 'Pre Procedure',
      alertMessage:
        'Please say "Alexa Open Pre Procedure" on the Alexa app, and after completion you can view your responses in the ANSWERS tab.',
      skillLink: 'https://skills-store.amazon.com/deeplink/tvt/5ec9a69697a92bd6997fb0bc5a7399f42af3cdc36c777823ca5e6d2bb9576b69f4bc0b764b717fd0b9a669cd9eaf0028f2a84f3478577902777216977e912ac5544c6c0a20f6a95f193d238db7efdb4afd41ed55dd02c3e53c7f4c8c96f6ff89ce90a8b8a309d9564bb5daa714754d3d'
    },
    {
      title: 'Post Procedure',
      alertMessage:
        'Please say "Alexa Open Post Procedure" on the Alexa app, and after completion you can view your responses in the ANSWERS tab.',
      skillLink: 'https://skills-store.amazon.com/deeplink/tvt/07a823efc16d56794344a69ef5627c167a117516895266957b83b7cacaef4be2fae93537a60475bb1c698f1e4f2e251d725c3cb6c98060103ae346624acc591da32a9a09c80720e26d16056a0e8dfadda4cb2d5792a985ae096eb1fe70126f9b5'
    },
    {
      title: 'Emergency',
      alertMessage:
        'Please say "Alexa Open Emergency" on the Alexa app, and after completion you can view your responses in the ANSWERS tab.',
      skillLink: '#' // Placeholder - update when link is available
    },
    {
      title: 'Anesthesia',
      alertMessage:
        'Please say "Alexa Open Anesthesia" on the Alexa app, and after completion you can view your responses in the ANSWERS tab.',
      skillLink: '#' // Placeholder - update when link is available
    },
  ];

  const handleActivate = (assessment) => {
    setModalMessage(assessment.alertMessage);
    setSelectedSkill(assessment.skillLink);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setModalMessage('');
    setSelectedSkill('');
  };

  const handleOk = () => {
    // Open the Amazon skill link in a new tab
    if (selectedSkill) {
      window.open(selectedSkill, '_blank');
    }
    setShowModal(false);
    navigate('/dashboard/nurse-chat/answers');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <Link to={"/dashboard/nurse-chat"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Nurse Chat Assessments
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="min-h-screen bg-gray-50 p-4 flex flex-col items-center">
        <div className="w-full max-w-lg space-y-4 mt-[100px]">
          {assessments.map((item, idx) => (
            <div
              key={idx}
              className="w-full flex items-center justify-between rounded-lg p-4 bg-gradient-to-r from-blue-400 to-blue-500"
            >
              <span className="text-white font-semibold text-lg">
                {item.title}
              </span>
              <button
                onClick={() => handleActivate(item)}
                className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded"
              >
                Activate
              </button>
            </div>
          ))}
        </div>

        {/* Modal */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6">
              <h2 className="text-xl font-semibold mb-4">Alert!</h2>
              <p className="mb-6">{modalMessage}</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 rounded border border-gray-300 hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={handleOk}
                  className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Box>
  );
}

export default NurseChatAssessments; 