import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Button,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableContainer,
  Container,
  Paper,
  TableBody,
  Modal,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  CardContent,
  Card,
  CardHeader,
  CardActions,
  Divider,
  Chip,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItem,
  List,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { Close } from "@mui/icons-material";
const VisualAnalysisMyRequests = () => {
  const { token } = useContext(AuthContext);
  useEffect(() => {
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API}${
          import.meta.env.VITE_APP_API_VERSION
        }/analysis/my-requests`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setRequests(response.data.analysisRequests);
      })
      .catch((error) => {
        console.error("Error fetching user details", error);
      });
  }, []);
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requests, setRequests] = useState([]);
  const handleOpen = (request) => {
    setSelectedRequest(request);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRequest(null);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Link to={"/visual-data"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">My Requests</Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ paddingTop: 5 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>Request ID</TableCell> */}
                <TableCell>Request Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests?.map((request) => (
                <TableRow key={request._id}>
                  {/* <TableCell onClick={() => handleOpen(request)}>{request._id}</TableCell> */}
                  <TableCell onClick={() => handleOpen(request)}>
                    <b>{request.title}</b>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color={
                        request.status === "Pending" ? "warning" : "success"
                      }
                      label={request.status}
                    ></Chip>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleOpen(request)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "600px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedRequest && (
              <>
                <Typography
                  variant="p"
                  className="text-cyan-600 flex justify-between"
                >
                  {selectedRequest._id}
                  <IconButton onClick={() => setOpen(false)}>
                    <Close></Close>
                  </IconButton>
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {selectedRequest.title}
                </Typography>
                <p>{selectedRequest.description}</p>
                <Chip
                  color={
                    selectedRequest.status === "Pending" ? "warning" : "success"
                  }
                  label={selectedRequest.status}
                ></Chip>

                <p>
                  <b>Submitted Image</b>
                </p>
                {selectedRequest.img && (
                  <img
                    style={{ height: 200, borderRadius: 15, margin: "10px 0" }}
                    src={ selectedRequest.img }
                  />
                )}
                {selectedRequest.video && (
                  <video
                    controls
                    style={{ height: 200, borderRadius: 15, margin: "10px 0" }}
                  >
                    <source
                      src={
                        import.meta.env.VITE_APP_BASE_API +
                        selectedRequest.video
                      }
                    ></source>
                  </video>
                )}
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      sx={{ color: "text.secondary", fontSize: 14 }}
                    >
                      <b>Doctor Assigned</b>
                    </Typography>
                    {selectedRequest?.assignedDoctor ? (
                      <Typography variant="body2">
                        <div>
                          <b>{selectedRequest.assignedDoctor.doctorName}</b>
                          <p>
                            {selectedRequest.assignedDoctor.department} |{" "}
                            {selectedRequest.assignedDoctor.hospital}
                          </p>
                        </div>{" "}
                        <Button size="small">Chat</Button>
                      </Typography>
                    ) : (
                      "No Doctor Assigned."
                    )}
                  </CardContent>
                </Card>
                <Typography variant="h6" gutterBottom>
                  Activities
                </Typography>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {selectedRequest.activities?.map((activity) => (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={[activity.description]}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: "text.primary",
                                  display: "inline",
                                }}
                              ></Typography>
                              {activity.timestamp}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  ))}
                </List>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default VisualAnalysisMyRequests;
