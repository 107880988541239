import { useContext, useRef, useState } from "react";
import {
  Button,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  TextField,
  Tab,
  Tabs,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";

const VisualDataImage = () => {
  const {token} = useContext(AuthContext);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [mode, setMode] = useState("image"); // Toggle between image and video modes
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageData, setImageData] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  // Start camera for both image and video capture
  const startCamera = async (cameraMode='image') => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();

      // Set up MediaRecorder for video recording
      if (cameraMode === 'video' ) {
        const recorder = new MediaRecorder(stream);
        const chunks = [];
        recorder.ondataavailable = (e) => chunks.push(e.data);
        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: "video/mp4" });
          setVideoBlob(blob);
        };
        setMediaRecorder(recorder);
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  // Capture Image
  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataUrl = canvas.toDataURL("image/png");
    setImageData(imageDataUrl);
  };

  // Start and Stop Video Recording
  const startRecording = async () => {
    if(!mediaRecorder){
      await startCamera('video');
      mediaRecorder.start();
    }else{
      mediaRecorder.start();
    }
  }
  const stopRecording = () => mediaRecorder.stop();

  // Submit Form
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (mode === "image" && imageData) {
      const blob = await fetch(imageData).then((res) => res.blob());
      formData.append("image", blob, "captured-image.png");
    }
    if (mode === "video" && videoBlob) {
      formData.append("video", videoBlob, "captured-video.mp4");
    }
   
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_APP_API_VERSION}/analysis/request`,
        formData,
        {
          withCredentials: true,
          headers: {   "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
        }
      );
  
      if (response.data.success) {
        alert("Request Submitted Successfully!");
        resetForm();
      } else {
        console.error("Submission failed", response.data);
      }
    } catch (error) {
      console.error("Error submitting request", error);
    }
  };
  // Reset Form
  const resetForm = () => {
    setTitle("");
    setDescription("");
    setImageData(null);
    setVideoBlob(null);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Link to={"/visual-data"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">Visual Analysis Request</Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item>
          <Box p={3}>
            <Typography variant="h5" gutterBottom>
              Instructions
            </Typography>
            
            <p>Some Instructions here... from the database as per the doctors specified.</p>
            <p>Some Instructions here... from the database as per the doctors specified.</p>
            <p>Some Instructions here... from the database as per the doctors specified.</p>
            <p>Some Instructions here... from the database as per the doctors specified.</p>
            
            <Typography variant="h5" gutterBottom marginTop={5}>
              Request Details
            </Typography>
            <TextField
              label="Request Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
            />
          </Box>
        </Grid>

        <Box item p={3} maxWidth="800px" mx="auto">
          <Tabs
            value={mode}
            onChange={(e, newValue) => {
              setMode(newValue);
              setImageData(null);
              setVideoBlob(null);
              setTimeout(()=>{
                startCamera(newValue);
              }, 500);
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
            sx={{ marginBottom: 3 }}
          >
            <Tab label="Capture Image" value="image" />
            <Tab label="Record Video" value="video" />
          </Tabs>

          {/* Camera and Capture Section */}
          <Box textAlign="center" className="flex flex-col items-center">
            <video
              ref={videoRef}
              width="100%"
              autoPlay
              style={{
                maxWidth: "400px",
                display: imageData || videoBlob ? "none" : "block",
                borderRadius: 15,
                marginBottom: 20,
              }}
            ></video>
            <canvas
              ref={canvasRef}
              style={{ display: "none", marginBottom: 20, borderRadius: 15 }}
            />

            {/* Capture Buttons */}
            {!imageData && !videoBlob && (
              <Box className="flex">
               
                {mode === "image" ? (
                   <>
                   <Button
                   variant="contained"
                   color="primary"
                   onClick={startCamera}
                   sx={{ mr: 2 }}
                 >
                   Start Camera
                 </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={captureImage}
                  >
                    Capture Image
                  </Button></>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={startRecording}
                    >
                      Start Recording
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={stopRecording}
                      sx={{ ml: 2 }}
                    >
                      Stop Recording
                    </Button>
                  </>
                )}
              </Box>
            )}

            {/* Preview Section */}
            {imageData && (
              <Box mt={2} className="flex flex-col justify-center">
                <Typography variant="subtitle1">Captured Image:</Typography>
                <img
                  src={imageData}
                  alt="Captured"
                  style={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "400px",
                    borderRadius: 15,
                  }}
                />
              </Box>
            )}
            {videoBlob && (
              <Box mt={2} className="flex flex-col justify-center">
                <Typography variant="subtitle1">Captured Video:</Typography>
                <video
                  controls
                  src={URL.createObjectURL(videoBlob)}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginBottom: 20,
                    borderRadius: 15,
                  }}
                ></video>
              </Box>
            )}
          </Box>

          {/* Submit Button */}
          <Box textAlign="center" mt={3}>
            <Button
              variant="contained"
              color="success"
              size="large"
              disabled={!title || !description || (!imageData && !videoBlob)}
              onClick={handleSubmit}
            >
              Submit Request
            </Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default VisualDataImage;
