import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button, Container, Avatar, Typography, Box, AppBar, Toolbar, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import { FaSpinner } from "react-icons/fa6";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-toastify";

const EditProfile = () => {
  const { token } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const {user, setUser} = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    const email = localStorage.getItem("email");
    const userDetails = user;
      const formFields = {firstName: userDetails.firstName, lastName: userDetails.lastName, alexaEmail: userDetails.alexaEmail, phone: userDetails.phone, address:  userDetails.address};
      Object.keys(formFields).forEach((key) => setValue(key, userDetails[key]));
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      // getUserDetails();
    }
  }, [navigate, setValue]);

  const getUserDetails = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_APP_API_VERSION}/user/patient/me`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const userDetails = response.data.user;
      const formFields = {firstName: userDetails.firstName, lastName: userDetails.lastName, alexaEmail: userDetails.alexaEmail, phone: userDetails.phone, address:  userDetails.address};
      Object.keys(formFields).forEach((key) => setValue(key, response.data.user[key]));
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  };

  const onSubmit = async (formData) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_APP_API_VERSION}/user/patient`,
        formData,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setUser(response.data.user)
        navigate("/profile");
        
      } else {
        console.error("Update failed");
      }
    } catch (error) {
      console.error("Error updating user", error);
      if (error.response?.data?.message === "The incoming token has expired") {
        navigate("/login");
      }
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Link to={'/profile'}>
            <IconButton size="large" edge="start" color="inherit" aria-label="back" sx={{ mr: 2 }}>
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">Edit Profile</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        {isSubmitting && <FaSpinner />}
        <Box display="flex" justifyContent="center" mt={4} mb={2}>
          <Avatar src="/assets/imgs/male.png" sx={{ width: 100, height: 100 }} />
        </Box>
        <Typography variant="h5" align="center" gutterBottom>
          Edit Your Details
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField fullWidth label="First Name" {...register("firstName")} margin="normal" />
          <TextField fullWidth label="Last Name" {...register("lastName")} margin="normal" />
          <TextField fullWidth label="Mobile Number" {...register("phone")} margin="normal" />
          <TextField fullWidth label="Address" {...register("address")} margin="normal" />
          <TextField fullWidth label="Alexa Id" {...register("alexaEmail")} margin="normal" />
          <Button variant="contained" color="secondary" fullWidth type="submit" sx={{ mt: 2 }}>
            {isSubmitting ? "Updating..." : "Update"}
          </Button>
        </form>
      </Container>
    </>
  );
};

export default EditProfile;
