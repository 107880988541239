import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';

const ShareDataPage = () => {
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [recipients, setRecipients] = useState({
    doctor: false,
    insurance: false,
    family: false
  });

  useEffect(() => {
    const storedAssessments = localStorage.getItem('selectedAssessments');
    if (storedAssessments) {
      setSelectedAssessments(JSON.parse(storedAssessments));
      console.log(JSON.parse(storedAssessments));
    }
  }, []);

  const handleCheckboxChange = (key) => {
    setRecipients((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleSend = async () => {
    // Map categories to actual emails
    const emailMap = {
      doctor: ['doctor@example.com'],
      insurance: ['insurance@example.com'],
      family: ['family@example.com']
    };

    // Aggregate chosen recipients
    let chosenEmails = [];
    if (recipients.doctor) chosenEmails = chosenEmails.concat(emailMap.doctor);
    if (recipients.insurance) chosenEmails = chosenEmails.concat(emailMap.insurance);
    if (recipients.family) chosenEmails = chosenEmails.concat(emailMap.family);

    if (chosenEmails.length === 0) {
      alert("Please select at least one recipient category.");
      return;
    }

  // Build email body using chosen assessments
      const emailBody = `
      Hello,

      The following assessments have been selected to share with you:

      ${selectedAssessments.map(assessmentObj => `
      Assessment ID: ${assessmentObj._id}
      Mobile: ${assessmentObj.mobile}
      Assessment Name: ${assessmentObj.assessment_name}
      Email: ${assessmentObj.email}
      Timestamp: ${assessmentObj.timestamp}

      Questions & Answers:
      ${assessmentObj.data.map(item => `
        Question: ${item.question}
        Answer: ${item.answer}
      `).join('\n')}
      `).join('\n\n')}

      Thank you,
      Your App
      `;


    try {
      const response = await fetch( import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION+'/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          recipients: chosenEmails,
          subject: 'Shared Assessments',
          htmlBody: `<p>${emailBody.replace(/\n/g, '<br/>')}</p>`,  // Convert newlines to breaks for HTML body
          textBody: emailBody,
          fromEmail: 'info@smartnurse.ai' // make sure this is verified in your SES account
        })
      });

      const result = await response.json();
      if (!response.ok) {
        // If the response isn't OK, show an error
        alert(`Error sending email: ${result.message || 'Unknown error'}`);
      } else {
        alert('Emails have been sent successfully!');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('There was an error sending the email. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-5 mt-[100px]">
      <Navbar />
      <div className="w-full max-w-4xl p-6 bg-blue-200 rounded-lg shadow-lg">
        <h1 className="text-xl font-bold mb-4">Share Data</h1>
        <p className="mb-4">Select a category to share data with the corresponding recipients:</p>
        
        <div className="space-y-4">
          <div 
            className="bg-gradient-to-r from-blue-500 to-blue-300 p-4 rounded-lg flex items-center justify-between cursor-pointer"
            onClick={() => handleCheckboxChange('doctor')}
          >
            <div className="flex items-center text-white font-bold">
              <input 
                type="checkbox" 
                checked={recipients.doctor} 
                onChange={(e) => e.stopPropagation()} 
                className="mr-4" 
              />
              Doctor
            </div>
          </div>

          <div 
            className="bg-gradient-to-r from-blue-500 to-blue-300 p-4 rounded-lg flex items-center justify-between cursor-pointer"
            onClick={() => handleCheckboxChange('insurance')}
          >
            <div className="flex items-center text-white font-bold">
              <input 
                type="checkbox" 
                checked={recipients.insurance} 
                onChange={(e) => e.stopPropagation()} 
                className="mr-4" 
              />
              Insurance Company
            </div>
          </div>

          <div 
            className="bg-gradient-to-r from-blue-500 to-blue-300 p-4 rounded-lg flex items-center justify-between cursor-pointer"
            onClick={() => handleCheckboxChange('family')}
          >
            <div className="flex items-center text-white font-bold">
              <input 
                type="checkbox" 
                checked={recipients.family} 
                onChange={(e) => e.stopPropagation()} 
                className="mr-4" 
              />
              Friends and Family
            </div>
          </div>
        </div>

        <button 
          className="w-full py-2 mt-6 bg-blue-500 text-white font-bold rounded-lg shadow-md"
          onClick={handleSend}
        >
          SEND
        </button>
      </div>
    </div>
  );
};

export default ShareDataPage;
