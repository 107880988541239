import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

const Location = ({ heading = 'Map View' }) => {
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static">
        <Toolbar>
          <Link to={'/profile'}>
          <IconButton edge="start" color="inherit"  aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {heading}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ flexGrow: 1, backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="https://placehold.co/600x400?text=Static+Map" alt="Static Map" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </Box>
    </Box>
  );
};

export default Location;
