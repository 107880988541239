import { useState } from "react";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        import.meta.env.VITE_APP_BASE_API +
        import.meta.env.VITE_APP_API_VERSION + "/user/forgot-password",
        { email },
        { withCredentials: true }
      );
      alert(data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <section className="container form-component" style={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>
      <h1>Forgot Password</h1>
      <form onSubmit={submitHandler} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <input
          type="email"
          placeholder="Enter your email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" style={{ padding: "0.75rem 1.25rem", borderRadius: "4px" }}>
          Send Reset Link
        </button>
      </form>
    </section>
  );
};

export default ForgotPassword;
