import { useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";

const VisualDataVideo = () => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  const startRecording = () => {
    const stream = videoRef.current.srcObject;
    const mediaRecorder = new MediaRecorder(stream, { mimeType: "video/webm" });

    mediaRecorderRef.current = mediaRecorder;
    setRecordedChunks([]);
    setIsRecording(true);

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setRecordedChunks((prev) => [...prev, event.data]);
      }
    };

    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(recordedChunks, { type: "video/webm" });
      const videoURL = URL.createObjectURL(videoBlob);
      setRecordedVideoURL(videoURL);
    };

    mediaRecorder.start();
  };
  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };
  const stopCamera = () => {
    if (videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Link to={"/visual-data"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">Record Video</Typography>
        </Toolbar>
      </AppBar>

      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <video
          ref={videoRef}
          autoPlay
          muted
          style={{ width: "100%", maxWidth: "600px", marginBottom: "10px" }}
        />
        {!isRecording ? (
          <Button
            variant="contained"
            color="primary"
            onClick={startCamera}
            style={{ marginBottom: "10px" }}
          >
            Start Camera
          </Button>
        ) : (
          <Button
            variant="contained"
            color="error"
            onClick={stopCamera}
            style={{ marginBottom: "10px" }}
          >
            Stop Camera
          </Button>
        )}

        <Button
          variant="contained"
          color={isRecording ? "secondary" : "primary"}
          onClick={isRecording ? stopRecording : startRecording}
          style={{ marginBottom: "10px" }}
          disabled={!videoRef.current}
        >
          {isRecording ? "Stop Recording" : "Start Recording"}
        </Button>
        {recordedVideoURL && (
          <Box mt={2} textAlign="center">
            <Typography variant="subtitle1">Recorded Video:</Typography>
            <video
              src={recordedVideoURL}
              controls
              style={{ width: "100%", maxWidth: "600px" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VisualDataVideo;
