import { AppBar, Toolbar, Typography, Container, Paper, IconButton } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function FaqPage() {
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
        <Link  to={"/settings"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            FAQs
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ paddingY: 4 }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant='p'>Coming Soon...</Typography>
        </Paper>
      </Container>
    </div>
  );
}
