import { AppBar, Toolbar, Typography, Container, IconButton } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function PrivacyPage() {
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
        <Link to={"/settings"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            SmartNurse.ai
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ paddingY: 4 }}>
      <h5>SMARTNURSE.AI HIPAA PRIVACY AUTHORIZATION & POLICY</h5>
  <span>Effective Date: January 1st , 2023</span>
  <p>
    This website www.smartnurse.ai and mobile app is operated by Smart Nurse (“Smart Nurse”, “app”, “we”, “our” or “us”).
     Smart Nurse is not a Covered Entity (Healthcare Provider) but a tracker of your life. Smart Nurse helps you to track 
     your life with the smart watches and also lets you enter the trackers you want to analyse, reminds you to do track 
     them at the time specified by you, and count their values on any day. After tracking your personal health data, 
     we provide technical support to enhance health and wellness with help of our authorized Healthcare Providers 
     (Covered Entities) partners. By using our website or creating an account with us, you give us the authorization 
     to use your protected health information as described in this Policy. This privacy authorization allows us to share your 
     information that you provide to us with our partners and also allows our partner healthcare providers (Covered entities) 
     to share your protected health information, including the results of test/s. This notice describes how medical information 
     about you may be used and disclosed and how you can get access to this information. Please review it carefully.
  </p>
  <h6>Our Obligation to You, the Client</h6>
  <p>
    We at Smart Nurse respect your privacy. This is part of our code of ethics. We are required by law to maintain the privacy of
    “protected health information” about you, to notify you of our legal duties and your legal rights, and to follow the privacy 
    policies described in this notice. “Protected health information” means any information that we create or receive that identifies 
    you and relates to your health or payment for services to you.
  </p>

  <h6>Your Rights</h6>
  <p>
    <strong>When it comes to your health information, you have certain rights.</strong> This section explains your rights and some of our 
    responsibilities to help you.
  </p>
 
  <h6>Get an electronic or paper copy of your medical record</h6>

    <ul>
      <li>You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you. Ask us how to do this.</li>
      <li>We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.</li>
    </ul>


  <h6>Ask us to correct your medical record OR any other personal information</h6>

    <ul>
      <li>You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.</li>
      <li>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</li>
    </ul>

  <h6>Request confidential communications</h6>

    <ul>
      <li>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.</li>
      <li>We will say “yes” to all reasonable requests.</li>
    </ul>

  <h6>Ask us to limit what we use or share</h6>

    <ul>
      <li>You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required to agree to your request, and we may say “no” if it would affect your care.</li>
      <li>If you pay for a service or health care item out-of-pocket in full, you can ask us not to share that information for the purpose of payment or our operations with your health insurer. We will say “yes” unless a law requires us to share that information.</li>
    </ul>  

  <h6>Get a list of those with whom we’ve shared information</h6>

   <li>You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why.</li>
   <li>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</li>

  <h6>Get a copy of this privacy notice</h6>

    You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.

  <h6>Choose someone to act for you</h6>

    <ul>
      <li>If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.</li>
      <li>We will make sure the person has this authority and can act for you before we take any action.</li>
    </ul>

  <h6>File a complaint if you feel your rights are violated</h6>
  
  <ul>
    <li>You can complain if you feel we have violated your rights by contacting us at info@smartnurse.ai</li>
    <li>You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by 
      sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting <a href="www.hhs.gov/ocr/privacy/hipaa/complaints">www.hhs.gov/ocr/privacy/hipaa/complaints/</a>.
    </li>
    <li>We will not retaliate against you for filing a complaint.</li>
  </ul>
  <h6>Your Choices</h6>
  <p>
    <strong>For certain health information, you can tell us your choices about what we share.</strong> If you have a clear preference 
    for how we share your information in the situations described below, talk to us. Tell us what you want us to do, 
    and we will follow your instructions.
  </p>
  <p>In these cases, you have both the right and choice to tell us to:</p>
  <ul>
    <li>Share information with your family, close friends, or others involved in your care</li>
    <li>Share information in a disaster relief situation</li>
    <li>Include your information in a hospital directory</li>
  </ul>
  <p>
  If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information 
  if we believe it is in your best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.
  </p>
  <p>In these cases, we never share your information unless you give us written permission:</p>
  <ul>
    <li>Marketing purposes</li>
    <li>Sale of your information</li>
    <li>Most sharing of psychotherapy notes</li>
  </ul>

  <p>In the case of fundraising:</p>
  <ul><li>We may contact you for fundraising efforts, but you can tell us not to contact you again.</li></ul>

  <h6>Our Uses and Disclosures</h6>
  <p>
    <strong>How do we typically use or share your health information?</strong>
  </p>
  <p>We typically use or share your health information in the following ways.</p>
  
  
  <p><strong>Treat you</strong></p>
  <p>We can use your health information and share it with other professionals who are treating you.
    Example: A doctor treating you for an injury asks another doctor about your overall health condition.
  </p>

  <p><strong>Run our organization</strong></p>
  <p>We can use and share your health information to run our practice, improve your care, and contact you when necessary.</p>
  <p>Example: We use health information about you to manage your treatment and services.</p>

  <p><strong>Bill for your services</strong></p>
  <p>We can use and share your health information to bill and get payment from health plans or other entities.</p>
  <p>Example: We give information about you to your health insurance plan so it will pay for your services.</p>

  <p><strong>How else can we use or share your health information?</strong></p>
  <p>We are allowed or required to share your information in other ways – usually 
    in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes.
  </p>

  <p><strong>Help with public health and safety issues</strong></p>
  <p>We can share health information about you for certain situations such as:</p>
  <ul>
      <li>Preventing disease</li>
      <li>Helping with product recalls</li>
      <li>Reporting adverse reactions to medications</li>
      <li>Reporting suspected abuse, neglect, or domestic violence</li>
      <li>Preventing or reducing a serious threat to anyone’s health or safety</li>
  </ul>

  <p><strong>Do research</strong></p>
  <p>We can use or share your information for health research.</p>

  <p><strong>Comply with the law</strong></p>
  <p>We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we’re complying with federal privacy law.</p>

  <p><strong>Respond to organ and tissue donation requests</strong></p>
  <p>We can share health information about you with organ procurement organizations.</p>

  <p><strong>Work with a medical examiner or funeral director</strong></p>
  <p>We can share health information with a coroner, medical examiner, or funeral director when an individual dies.</p>

  <p><strong>Address workers’ compensation, law enforcement, and other government requests</strong></p>
  <p>We can use or share health information about you:</p>

  <ul>
    <li>For workers’ compensation claims</li>
    <li>For law enforcement purposes or with a law enforcement official</li>
    <li>With health oversight agencies for activities authorized by law</li>
    <li>For special government functions such as military, national security, and presidential protective services</li>
  </ul>

  <p><strong>Respond to lawsuits and legal actions</strong></p>
  <p>We can share health information about you in response to a court or administrative order, or in response to a subpoena.</p>

  <p><strong>Our Responsibilities</strong></p>
  <ul>
    <li>We are required by law to maintain the privacy and security of your protected health information.</li>
    <li>We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.</li>
    <li>We must follow the duties and privacy practices described in this notice and give you a copy of it.</li>
    <li>We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.</li>
  </ul>

  <p><strong>Changes to the Terms of this Notice</strong></p>
  <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

  <p>“Smartnurse.ai” shall be entitled to update and change provisions of this Privacy Policy at any time. Any new version of the Privacy Policy shall be effective from its placing unless otherwise provided in such new version of the Privacy Policy. We recommends users consult this Privacy Policy 
    on a regular basis to keep up with the latest version.
  </p>

  <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

  <p><strong>Contact Us</strong></p>
  <p>If you have any questions about the Privacy Policy, please contact us by sending an email to <a href="mailto:info@smartnurse.ai">info@smartnurse.ai</a></p>

      </Container>
    </div>
  );
}
