import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemText, ListItemIcon, Switch, CircularProgress } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import { AuthContext } from '../Context/AuthContext';
import { toast } from 'react-toastify';

const NotificationPage = () => {
  const [isToggleEmailChecked, setIsToggleEmailChecked] = useState(false);
  const [isToggleSmsChecked, setIsToggleSmsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
 const { token } = React.useContext(AuthContext);
  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const fetchNotificationSettings = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_APP_API_VERSION}` + '/preferences/get', {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status) {
        const { emailNotification, smsNotification } = res.data;
        setIsToggleEmailChecked(emailNotification);
        setIsToggleSmsChecked(smsNotification);
      }
    } catch (error) {
      console.error('Error fetching notification settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateNotificationDetails = async (smsNotification, emailNotification) => {
    setLoading(true);
    try {
      const res = await axios.post(`${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_APP_API_VERSION}` + '/preferences/update', { smsNotification,emailNotification }, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status) {
        toast.success('The notification information successfully updated');
      } else {
        toast.warn('The notification information not updated. Please try again');
      }
    } catch (error) {
      console.error('Error updating notification details:', error);
      toast.error('Error updating notification details. Please try again');
    } finally {
      setLoading(false);
    }
  };

  const updateEmail = (event) =>{
    setIsToggleEmailChecked(event.target.checked);
    updateNotificationDetails(isToggleSmsChecked, event.target.checked);
  }
  const updateSms = (event) =>{
    setIsToggleSmsChecked(event.target.checked);
    updateNotificationDetails(event.target.checked, isToggleEmailChecked);
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
        <Link to={"/profile"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">Notifications</Typography>
        </Toolbar>
      </AppBar>
      <div className="p-4">
        {loading && <CircularProgress />}
        <List>
          <ListItem>
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Email" />
            <Switch
              checked={isToggleEmailChecked}
              onChange={updateEmail}
              color="primary"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="SMS" />
            <Switch
              checked={isToggleSmsChecked}
              onChange={updateSms }
              color="primary"
            />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default NotificationPage;
