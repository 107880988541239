import React, { useContext, useEffect, useState } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import Navbar from '../../components/Navbar';
import { AuthContext } from '../../Context/AuthContext';
import axios from 'axios';

const AnswersDashboard = () => {
  const { isAuthenticated, token } = useContext(AuthContext);
  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedAssessments, setSelectedAssessments] = useState([]);

  const fetchUser = async () => {
    if (isAuthenticated) {
      const response = await axios.get(
        import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/get-assessments",
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAssessments(response.data.assessments);
      setFilteredAssessments(response.data.assessments);
    } else {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated]);

  const getProName = (name) => {
    if (name === 'preprocedure') {
      return 'Pre Procedure';
    }
    if (name === 'postprocedure') {
      return 'Post Procedure';
    } else {
      return name;
    }
  };

  function convertToHumanReadable(dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hours = dateString.slice(8, 10);
    const minutes = dateString.slice(10, 12);

    const date = new Date(year, month - 1, day, hours, minutes);
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString('en-US', options);
  }

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSearch = () => {
    if (fromDate && toDate) {
      const filtered = assessments.filter((assessment) => {
        const timestamp = new Date(
          parseInt(assessment.timestamp.slice(0, 4)),
          parseInt(assessment.timestamp.slice(4, 6)) - 1,
          parseInt(assessment.timestamp.slice(6, 8)),
          parseInt(assessment.timestamp.slice(8, 10)),
          parseInt(assessment.timestamp.slice(10, 12))
        );
        const from = new Date(fromDate);
        const to = new Date(toDate);
        return timestamp >= from && timestamp <= to;
      });
      setFilteredAssessments(filtered);
    }
  };

  const toggleSelection = (item) => {
    console.log(item);
    setSelectedAssessments((prev) => {
      if (prev.some((selectedItem) => selectedItem._id === item._id)) {
        return prev.filter((selectedItem) => selectedItem._id !== item._id);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleSelectUsersClick = () => {
    if (selectedAssessments.length === 0) {
      alert("Please select at least one assessment.");
      return;
    }
    // Store selected assessments in localStorage (or pass via query param)
    localStorage.setItem('selectedAssessments', JSON.stringify(selectedAssessments));
    window.location.href = "/dashboard/share-data/list"; // Adjust route as needed
  };

  const handleNavigate = (item) => {
    localStorage.setItem('selectedQuestion', JSON.stringify(item.data));
    window.location.href = `/dashboard/share-data/answers/${item._id}`;
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-5">
      <Navbar />
      <div className="w-full max-w-4xl p-6 bg-blue-200 rounded-lg shadow-lg mt-[100px]">
        {/* Add Select Users button */}
        <button 
          className="w-full py-2 bg-red-500 text-white rounded-lg shadow-md mb-4"
          onClick={handleSelectUsersClick}
        >
          SELECT USERS
        </button>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block mb-2">From</label>
            <div className="flex items-center border border-gray-300 rounded-lg p-2 bg-white">
              <FaCalendarAlt className="text-gray-400 mr-2" />
              <input type="date" className="flex-1 outline-none" value={fromDate} onChange={handleFromDateChange} />
            </div>
          </div>
          <div>
            <label className="block mb-2">To</label>
            <div className="flex items-center border border-gray-300 rounded-lg p-2 bg-white">
              <FaCalendarAlt className="text-gray-400 mr-2" />
              <input type="date" className="flex-1 outline-none" value={toDate} onChange={handleToDateChange} />
            </div>
          </div>
        </div>
        <button className="w-full py-2 bg-red-500 text-white rounded-lg shadow-md" onClick={handleSearch}>SEARCH</button>
      </div>
      <div className="mt-6 w-full max-w-4xl">
        {filteredAssessments.map((item, index) => (
          <div 
            key={index} 
            className="flex items-center justify-between p-4 bg-gradient-to-r from-blue-500 to-blue-300 rounded-lg shadow-md mb-4"
          >
            <div className="flex items-center">
              {/* Checkbox only toggles selection */}
              <input 
                type="checkbox" 
                className="mr-4" 
                checked={selectedAssessments.includes(item._id)}
                onChange={() => toggleSelection(item)}
              />

              {/* Clicking on the name navigates */}
              <div 
                className="text-white font-bold cursor-pointer"
                onClick={() => handleNavigate(item)}
              >
                {getProName(item.assessment_name)}
              </div>
            </div>
            <div className="text-white">{convertToHumanReadable(item.timestamp)}</div>
            {/* Arrow button also navigates */}
            <button 
              className="text-white font-bold"
              onClick={() => handleNavigate(item)}
            >
              &gt;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnswersDashboard;
