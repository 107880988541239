import { AppBar, Toolbar, Typography, Container, Card, CardContent, CardHeader, Grid, Box, IconButton } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function HowToUseAISentinelPage() {
    const Vsrc = '';
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
        <Link to={"/settings"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            SmartNurse.ai
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ paddingY: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          How to Use AI Sentinel
        </Typography>

        <Typography align="center" gutterBottom>
          Wear it and turn it on. It’s that simple!
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
          <iframe
            src={Vsrc} title='Video'
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ width: '100%', maxWidth: '560px', height: '315px' }}
          ></iframe>
        </Box>

        <Grid container spacing={3}>
          {steps.map((step) => (
            <Grid item xs={12} key={step.number}>
              <Card>
                <CardHeader
                  title={step.title}
                  subheader="Health ‘Assessment’ Triage! (When it Matters Most)"
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                />
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    {step.images && (
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        {step.images.map((image) => (
                          <Box key={image} component="img" src={image} alt="" sx={{ height: 50 }} />
                        ))}
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <Typography variant="h4" align="center">{step.number}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>{step.description}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

const steps = [
  {
    number: 1,
    title: 'JUST-In-Time',
    images: [
      'https://SmartNurse.ai/wp-content/uploads/2022/07/Apple-Watch-No-Bg.png',
      'https://SmartNurse.ai/wp-content/uploads/2022/07/FitBit-No-Bg.png',
      'https://SmartNurse.ai/wp-content/uploads/2022/06/Red-removebg-preview.png',
      'https://SmartNurse.ai/wp-content/uploads/2022/06/1.png',
    ],
    description: 'You can easily Setup AI Sentinel on your cell phone and sync wearable of choice to activate',
  },
  {
    number: 2,
    title: 'Continuous Vital Check',
    images: ['https://SmartNurse.ai/wp-content/uploads/2022/07/Step-2.png'],
    description: 'AI sentinel provides continuous VITALS to Anybody/Anytime/Anywhere!',
  },
  {
    number: 3,
    title: 'Constant Medical Check',
    images: ['https://SmartNurse.ai/wp-content/uploads/2022/07/Constant-Medical-Test.png'],
    description: 'AI sentinel watches over you 24/7, and when Preset thresholds are triggered (e.g.: 105 Deg Temp / High BP /Low Blood Oxygen)',
  },
  {
    number: 4,
    title: 'JUST–IN–TIME HEALTH TRIAGE',
    images: ['https://SmartNurse.ai/wp-content/uploads/2022/07/JUST–IN–TIME-HEALTH-TRIAGE.png'],
    description: 'AI Sentinel Health ASSESSMENT screening Intervention is activated, using YOUR preferred Voice App (Siri/Assistant/Echo)',
  },
  {
    number: 5,
    title: 'Nurse/MD Intervention',
    images: ['assets/imgs/5.jpeg'],
    description: 'Nurses and MDs receive your ASSESSMENT/S and take corrective steps INSTANTLY, including additional custom Assessments saving LIVES & MONIES',
  },
];
