import { useContext, useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, IconButton, Grid, Box, Card, CardContent, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import axios from 'axios';
import { AuthContext } from '../Context/AuthContext';

const DoctorsListPage = () => {
  const [emails, setEmails] = useState([]);
  const [txtEmail, setTxtEmail] = useState('');
  const baseapiUrl = `${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_APP_API_VERSION}/careteam`;
  const {token}  = useContext(AuthContext);

  useEffect(() =>{
    getEmailList();
  }, []);

  const apiOption = {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  }

  const getEmailList = async  () =>{
     const res = await axios.get(baseapiUrl+'/doctor', apiOption);
     if(res.status){
      setEmails(res.data ?? []);
     }
  }

  const addEmail = async (emailAddress) => {
     const res = await axios.post(baseapiUrl,{emailAddress, personType: 'doctor'} , apiOption);
     if(res.status){
      setEmails([...emails, res.data.emailRecord]);
     }
    setTxtEmail('');
  };

  const removeEmail = async (emailItem) => {
    const res = await axios.delete(baseapiUrl+'/'+emailItem._id, apiOption);
    if(res.status){
     setEmails(emails.filter(item => item._id != emailItem._id));
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Link to={"/profile"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">doctors List</Typography>
        </Toolbar>
      </AppBar>
     <Paper>
     <Box padding={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              type="text"
              value={txtEmail}
              onChange={(e) => setTxtEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => addEmail(txtEmail)}
            >
              Add Email
            </Button>
          </Grid>
        </Grid>
        <Box marginTop={4}>
          <Typography variant="h6">doctors</Typography>
          {emails.map((email, index) => (
            <Card key={email.emailAddress} sx={{ marginBottom: "10px" }}>
              <CardContent>
                <Grid
                  containeralignItems="center"
                  justifyContent={"space-between"}
                >
                  <Grid item sm={'auto'}>
                    <Typography variant="body1">
                      <b>{email.emailAddress}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={"auto"}>
                    {/* <IconButton onClick={openInformation}>
                      <InfoIcon />
                    </IconButton>
                    <IconButton onClick={openNotification}>
                      <NotificationsIcon />
                    </IconButton> */}
                    <IconButton onClick={() => removeEmail(email)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
     </Paper>
    </div>
  );
};
export default DoctorsListPage;
