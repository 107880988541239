import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Card from "./Card";

const MainDashboard = () => {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <>
      <Navbar />
      {/* set bg to a image file */}
      <div className="mt-[100px]  dash-main-bg bg-cover bg-center">
        <div className="grid grid-cols-2 gap-6 p-10 rounded-lg shadow-lg max-w-5xl mx-auto md:grid-cols-3">
          <Link to={"/profile"}>
            <Card icon="/cards/profile.png" title="Profile" />
          </Link>
          <Card icon="/cards/patient.png" title="Vitals" />
          <Card icon="/cards/nurse.png" title="FAQs" />
          <div onClick={() => handleCardClick("dashboard/nurse-chat")}>
            <Card icon="/cards/voice.png" title="Nurse Chat" />
          </div>
          <Link to={"/visual-data"}>
            <Card icon="/cards/visual.png" title="Visuals Data" />
          </Link>
          <Card icon="/cards/medications.png" title="Medications" />
          <Link to={"/dashboard/share-data"}>
            {" "}
            <Card icon="/cards/share.png" title="Share Data" />
          </Link>

          <Card icon="/cards/pain.png" title="Pain Data" />
          <Link to={"/dashboard/appointments"}>
            <Card
              icon="/cards/pain.png"
              title="Appointments"
              to={"/dashboard/appointments"}
            />
          </Link>
        </div>
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 p-3 bg-red-500 text-white rounded-full shadow-lg">
          HELP
        </div>
      </div>
    </>
  );
};

export default MainDashboard;
