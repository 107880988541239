import { AppBar, Toolbar, Typography, Container, Paper, IconButton } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function TermsPage() {
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
        <Link to={"/settings"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            SmartNurse.ai
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ paddingY: 4 }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h4" gutterBottom>
            SmartNurse.ai is HIPAA compliant
          </Typography>

          <h5>Smartnurse.AI Terms of Use</h5>
 
 <h6>1. Object and Validity</h6>
 <p>Last updated: January 1st, 2023.</p><p> Email address: info@smartnurse.ai </p>

 
 <p>
   Smartnurse.AI app (hereinafter referred to as “Smartnurse.AI”) operates a platform for tracking your life (hereinafter referred to as “Platform”) on 
   the Internet portals https://www.smartnurse.ai, as well as on mobile applications (as “App”). In this context, Smartnurse.AI lets you enter the trackers 
   you want to analyse, reminds you to do track them at the time specified by you, and count their values on any day. Smartnurse.AI will visualize your input
   data in the form of graphs and charts or use text stats about all values.
 </p>
 <p>
   The use of the platform (including the apps) shall be subject to the following terms and conditions valid at the time of registration or order. 
   Smartnurse.AI does not acknowledge any different general terms and conditions belonging to you unless Smartnurse.AI agrees to their validity in writing.
 </p>

 <p>
   No separate contract text is provided for the Agreement pertaining to the use of the platform. The content of the agreement between you and Smartnurse.
   AI is based on these terms and conditions,), that will be saved by Smartnurse.AI and that is available in your user account at any time.
 </p>

 <p>You accept the validity and applicability of these terms and conditions by using Smartnurse.AI app and/or purchasing a paid subscription, as the case may be.</p>

 <h6>2. Purchases</h6>

 <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) 
   the information you supply to us is true, correct and complete.
 </p>

 <p>
   The service may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, 
   you grant us the right to provide the information to these third parties subject to our Privacy Policy.
 </p>

 <h6>3. Availability, Errors and Inaccuracies</h6>

 <p>may experience delays in updating information on the our app and website (as “Service”) and in our advertising on other web sites. The information found on 
   the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described inaccurately, or unavailable 
   on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service.
 </p>

 <p>
   We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.
 </p>

 <h6>4. Paid subscription</h6>

 <h6>a. Subscription description</h6>
 <p>To be able to fully benefit from Smartnurse.AI services, additional service packages in the form of paid subscriptions (“Subscription access”) 
   will be made available to you after starting to use App. These can be purchased for different, and variable periods of time, which you will see in the order options.
 </p>

 <p>Smartnurse.AI subscriptions are paid subscriptions that allow you to access additional content and features through our mobile application. All the premium packages offer the same sets of premium features:</p>

 <ul>
   <li>Unlimited trackers</li>
   <li>The stats of unlimited trackers</li>
   <li>The dark theme</li>
 </ul>

 <p>We currently offer the following subscription / service package:</p>

 <ul>
   <li>1 Month Premium service package will charge you on a monthly basis</li>
   <li>1 year Premium service package will charge you on a yearly basis
     You will have access to the respective services as long as your subscription is valid.
   </li>
 </ul>

 <h6>b. Purchase agreement</h6>

 <p>You submit a legally binding offer for a fee-based subscription, if you enter the information 
   requested in the online order form and then click on the “Purchase” button. By doing so, you submit a legally binding offer for an agreement for a paid subscription. Before submitting your order, 
   you may change and view the data at any time.
 </p>

 <p>You can get a paid subscription through an in-app purchase through our iOS app or Android app. In order to do this, you must select the desired option on the subscription screen within the app. 
   As soon as you finish, the payment will be made to your account.
 </p>

 <h6>5. Cancellation Policy</h6>

 <p>If you are a consumer (i.e., a person completing the legal transaction for purposes that can be predominantly attributed to neither their commercial nor their independent professional activity), 
   you are entitled to a legal withdrawal right when executing a distance selling agreement, so in this case when signing an agreement for a fee-based subscription with Smartnurse.AI, about which Smartnurse.
   AI informs below, as per the requirement of the statutory model. Please note that in the case of an in-app purchase, you have the right to withdraw from the respective operator of the app store, i.e., Apple.
 </p>

 <h6>Subscription Cancellation</h6>

 <p>The free usage agreement starts from the time you start using the app and runs indefinitely. You may cancel the free usage agreement at any time. To this effect, you can delete your app.</p>

 <p>The paid subscription access will run for at least the term you originally selected and will automatically renew if it is not canceled within 24 hours of the end of that period. The subscription term will be 
   extended according to the subscription model used so far. You can cancel as instructed below:
 </p>

 <ul>
   <li>
     A paid subscription completed in our iOS or Android app or can be cancelled in your iTunes settings or play store.
   </li>
 </ul>

 <p>
   The subscription access cancellation takes effect on the day following the last day of your current subscription period and you will be downgraded to the free subscription agreement (free account). 
   However, once you renew a subscription again, you will be able to access your old status and use unlimited trackers. If you wish to delete your data, you can delete any tracker and any value or just delete the app. 
   All data will be deleted irrevocably.
 </p>

 <p>The right to blocking and cancellation for an important reason due to a good cause remains unaffected by the preceding provisions.</p>

 <h6>6. Copyright Policy</h6>

 <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p>

 <p>If you are a copyright owner or authorized on behalf of one and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to info@smartnurse.ai with the subject line: 
   “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement:
 </p>

 <ul>
   <li>a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</li>
   <li>identification of the URL or other specific location on our site or the App where the material that you claim is infringing is located; your address, telephone number, and email address;</li>
   <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
   <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
 </ul>

 <p>You can contact our Copyright Agent via email at info@smartnurse.ai</p>

 <h6>7. Intellectual Property</h6>

 <p>The Service and its original content, features and functionality are and will remain the exclusive property of Smartnurse.AI and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. 
   Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Smartnurse.AI.
 </p>

 <h6>8. Links To Other Web Sites</h6>
 <p>Our Service may contain links to third party web sites or services that are not owned or controlled by Smartnurse.AI.</p>

 <p>Smartnurse.AI has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>

 <p>You acknowledge and agree that Smartnurse.AI shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.</p>

 <p>We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.</p>

 <h6>9. Account Termination</h6>

 <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

 <p>All provisions of the Terms which by their nature should survive termination shall survive termination. including. without limitation. ownership provisions warranty disclaimers, indemnity and limitations of liability.</p>

 <h6>10. Indemnification</h6>

 <p>You agree to defend, indemnify and hold harmless Smartnurse.AI and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, 
   and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.
 </p>

 <h6>11. Limitation Of Liability</h6>

 <p>
   In no event shall Smartnurse.AI, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, 
   or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, 
   use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, 
   and even if a remedy set forth herein is found to have failed of its essential purpose.
 </p>

 <h6>12. Disclaimer</h6>

 <p>
   Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, 
   fitness for a particular purpose, non- infringement or course of performance.
 </p>

 <p>
   Smartnurse.AI and its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) 
   the results of using the Service will meet your requirements.
 </p>

 <h6>13. Exclusions</h6>

 <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or Smartnurse.AI damages, so the limitations above may not apply to you.</p>

 <h6>14. Governing Law</h6>

 <p>These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.</p>

 <p>
   Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. 
   These Terms constitute the entire agreement between us regarding our service, and supersede and replace any prior agreements we might have had between us regarding the Service.
 </p>

 <h6>15. Changes</h6>

 <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

 <p>
   When our Terms of Use changes, we will update the information on our site: https://smartnurse.ai
   By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you should not use our Service.
 </p>

 <h6>Contact Us</h6>
 <p>If you have any questions about these Terms, please contact us by sending an email to <a href="mailto:info@smartnurse.ai">info@smartnurse.ai</a></p>
        </Paper>
      </Container>
    </div>
  );
}
